<template>
  <div v-if="$store.getters.ready">
    <Header v-if="$route.params.group && $route.params.project" nav="project" :group="$route.params.group" :project="$route.params.project" active="settings" />
    <Header v-else />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 v-if="$route.params.group && $route.params.project" class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${$route.params.group}`" class="no-link">{{ groupname }}</router-link> &bull; <router-link :to="`/${$route.params.group}/${$route.params.project}`" class="no-link">{{ form.name }}</router-link></div> Settings</h5>
              <h5 v-else class="page-title-heading mt-1 py-2">New Project</h5>
            </div>
          </div>
          <div v-if="!$route.params.group && !$route.params.project" class="mb-3"></div>

          <div class="widget-list">
            <form class="form-horizontal" @submit.prevent="send">

              <div class="row">
                <div class="col-md-3 pr-3 d-none d-md-block" v-if="!$route.params.group && !$route.params.project">
                  <p>A project groups together files, issues, and other assets.</p>
                </div>

                <div class="col">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Project Name</label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.name" class="form-control" maxlength="100" placeholder="My Project" required />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Project URL</label>
                          <div class="col-md-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">https://{{ $config.domain }}/</span>
                              </div>
                              <select class="form-control custom-select" v-model="form.group">
                                <option v-for="g in groups" :key="g.id" :value="g.id">{{ g.path }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <input type="text" v-model="form.path" class="form-control" maxlength="100" placeholder="my-project" required />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Description</label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.description" class="form-control" maxlength="255" :placeholder="`Website for ${form.name ? form.name : 'My Project'}`" required />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Board Layout</label>
                          <div class="col-md-10">
                            <div class="radiobox radio-primary">
                              <label><input type="radio" v-model="form.boards_display_vertical" :value="false"><span class="label-text">Kanban</span></label>
                            </div>
                            <div class="radiobox radio-primary">
                              <label><input type="radio" v-model="form.boards_display_vertical" :value="true"><span class="label-text">Milestones</span></label>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Notes (Private)</label>
                          <div class="col-md-10">
                            <Textarea v-model="form.notes" rows="5" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Jira (Private)</label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.jira.server" class="form-control" maxlength="255" placeholder="Server" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-2 col-form-label"></label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.jira.username" class="form-control" maxlength="100" placeholder="Username" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-2 col-form-label"></label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.jira.api_key" class="form-control" maxlength="100" placeholder="API Key" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Billing</label>
                          <div class="col-md-10">
                            <table class="table">
                              <thead><tr><th>Description</th><th class="col-2 text-center">Amount</th><th class="col-2 text-center">Frequency</th><th class="col-2 text-center" v-if="charges.length > 0">Last Charge</th><th class="col-1" v-if="charges.length > 0"></th></tr></thead>
                              <tbody>
                                <tr v-for="c in charges" :key="c.id">
                                  <td>{{ c.description }}</td>
                                  <td class="text-center">{{ (c.amount / 100).toFixed(2) }}</td>
                                  <td class="text-center">{{ c.frequency | formatFrequency }}</td>
                                  <td class="text-center">{{ c.last_charge ? `${$datefns.fromNow(c.last_charge)}` : "Never" }}</td>
                                  <td class="text-right"><button type="button" class="btn btn-link py-0 px-2" title="Delete" @click="deleteCharge(c.id)"><i class="fal fa-times fa-lg text-primary opacity-08"></i></button></td>
                                </tr>
                                <tr>
                                  <td><input type="text" v-model="charge.description" class="form-control" /></td>
                                  <td><input type="text" v-model="charge.a" class="form-control text-center" @keypress="isNumber($event)" /></td>
                                  <td>
                                    <select v-model="charge.frequency" class="form-control custom-select text-center">
                                      <option value=""></option>
                                      <option value="0">One Time</option>
                                      <option value="7 days">Weekly</option>
                                      <option value="14 days">Biweekly</option>
                                      <option value="1 mon">Monthly</option>
                                      <option value="2 mons">Bimonthly</option>
                                      <option value="3 mons">Quarterly</option>
                                      <option value="6 mons">Semiannually</option>
                                      <option value="1 year">Annually</option>
                                    </select>
                                  </td>
                                  <td v-if="charges.length > 0"></td>
                                  <td v-if="charges.length > 0"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div v-if="$route.params.group && $route.params.project" class="row">
                    <div class="col-6"><button type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-check mr-2"></i>Save Changes</button></div>
                    <div class="col-6 text-right"><button type="button" class="btn text-danger my-3" v-b-modal.deletemodal><i class="fal fa-times mr-2"></i>Delete Project</button></div>
                  </div>
                  <button v-else type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-plus mr-2"></i>Create Project</button>
                </div>
              </div>

            </form>
          </div>

          <b-modal id="deletemodal" title="Delete Project?" centered>
            This project will be deleted, including all issues, comments, files and other data
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteProject()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import Textarea from '@/components/Textarea.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);

export default {
  data() {
    return {
      groups: [],
      groupname: '',
      form: {
        group: '',
        path: '',
        name: '',
        description: '',
        boards_display_vertical: false,
        notes: '',
        jira: {
          username: '',
          api_key: '',
          server: '',
        },
      },
      form_changed: false,
      charges: [],
      charge: {
        description: undefined,
        amount: 0,
        a: '',
        frequency: '',
      },
    };
  },
  filters: {
    formatFrequency(v) {
      switch (v) {
        case '0':
          return 'One Time';
        case '7 days':
          return 'Weekly';
        case '14 days':
          return 'Biweekly';
        case '1 mon':
          return 'Monthly';
        case '2 mons':
          return 'Bimonthly';
        case '3 mons':
          return 'Quarterly';
        case '6 mons':
          return 'Semiannually';
        case '1 year':
          return 'Annually';
        default:
          return v;
      }
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$store.getters.ready) {
          this.form_changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    isNumber(e) {
      const evt = (e) || window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) || Number.isNaN(Number(evt.target.value + evt.key))) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    async send() {
      this.form.path = this.form.path.toLowerCase();

      let mode = 'update';
      let path = `${this.$route.params.group}%2F${this.$route.params.project}`;
      if (!this.$route.params.group || !this.$route.params.project) {
        for (let i = 0; i < this.groups.length; i += 1) {
          if (this.groups[i].id === this.form.group) {
            mode = 'create';
            path = `${this.groups[i].path}%2F${this.form.path}`;
            break;
          }
        }
      }
      try {
        if (mode === 'create') {
          await this.$api.post('/projects', this.form);
          // create labels
          this.$api.post(`/projects/${path}/labels`, { name: 'Bug', color: 'D13D49', description: 'Unexpected problem or unintended behavior' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Duplicate', color: 'D2DAE1', description: 'Similar to an existing issue' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Invalid', color: 'D2DAE1', description: 'The issue is no longer relevant' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Question', color: '5EBEFF', description: 'The issue needs more information' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Enhancement', color: 'FEF2C1', description: 'New feature requests' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Design', color: '126879', description: 'Design improvements and fixes' });
          this.$api.post(`/projects/${path}/labels`, { name: 'Development', color: '0D56C9', description: 'Development issues' });
          this.$api.post(`/projects/${path}/labels`, { name: 'SEO', color: 'F8D0C5', description: 'SEO improvements and fixes' });
          if (this.$config.name !== 'agiler') {
            this.$api.post(`/projects/${path}/labels`, { name: 'Completed', color: '38d57a', description: 'Completed tasks' });
            this.$api.post(`/projects/${path}/labels`, { name: 'In Progress', color: 'C0E1C9', description: 'Tasks in progress' });
            this.$api.post(`/projects/${path}/labels`, { name: 'Setup', color: '542AE4', description: 'Setup tasks' });
            this.$api.post(`/projects/${path}/labels`, { name: 'To-do', color: 'D5411D', description: 'To-do tasks' });
          }
        } else {
          await this.$api.put(`/projects/${path}`, this.form);
        }
        // clear cache
        this.$cache.api.remove('/projects');

        // add charges
        if (this.charge.description) {
          this.charge.amount = Number((this.charge.a * 100).toFixed());
          const c = await this.$api.post(`/projects/${path}/charges`, this.charge);
          this.charges.push(c.data);
          this.charge.description = undefined;
          this.charge.a = '';
          this.charge.frequency = '';
        }

        if (mode === 'create') {
          this.$router.push(`/${path.replace('%2F', '/')}`);
        } else if (this.form_changed) {
          toast.icon(this, 'success', 'fa-check');
        }
        this.form_changed = false;
      } catch (e) {
        toast.danger(this, e.response.data.message);
      }
    },
    deleteCharge(id) {
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/charges/${id}`)
        .then(() => {
          for (let i = 0; i < this.charges.length; i += 1) {
            if (this.charges[i].id === id) {
              this.charges.splice(i, 1);
              break;
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteProject() {
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)
        .then(() => {
          this.$cache.api.remove('/projects');
          this.$router.push('/dashboard');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    if (!this.$auth.profile.site_admin) {
      this.$store.commit('error', 404);
      return;
    }
    try {
      if (this.$route.params.group && this.$route.params.project) {
        const project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
        this.groupname = project.group.name;
        this.form.group = project.group.id;
        this.form.path = project.path;
        this.form.name = project.name;
        this.form.boards_display_vertical = project.boards_display_vertical;
        this.form.description = project.description;
        this.form.notes = project.notes;
        if (project.jira) {
          this.form.jira.username = project.jira.username;
          this.form.jira.api_key = project.jira.api_key;
          this.form.jira.server = project.jira.server;
        }
        this.charges = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/charges`)).data;
      }
      this.groups = (await this.$api.get('/groups')).data;
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    Textarea,
  },
};
</script>
