<template>
  <textarea v-if="!autosize" class="form-control" :value="value" @input="$emit('input', $event.target.value)"><slot></slot></textarea>
  <textarea-autosize v-else class="form-control" :value="value" @input="$emit('input', $event)"><slot></slot></textarea-autosize>
</template>

<script>
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(TextareaAutosize);

export default {
  props: {
    autosize: Boolean,
    value: String,
  },
};
</script>
